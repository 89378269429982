.application__submit {
  margin-top: 1rem;
}

.application__form-buttons {
  padding-top: 2rem;
}

.application__collaborator {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;

  .application__collaborator-name {
    font-weight: bold;
  }
}

.panel-inner-block {
  flex: 1;
}

.content .application--list {
  margin-top: 0;
}

.application__form {

  .label.is-required::after {
    content: '*';
    padding-left: 0.1rem;
  }

  .file-field {
    flex-direction: column;
    display: inline-flex;
    flex-wrap: nowrap;
    word-break: break-all;

  }

  .file-container {
    position: relative;
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
  }

  .file--filled {

    .file-field {
      margin-right: 1rem;
      width: 50%;
    }

    .file-input {
      left: 50%;
    }
  }

  .file--filled,
  .file--empty {
    .help {
      font-size: 1rem;
    }
  }
}

.application--accept {
  margin-bottom: 1rem;

  strong {
    vertical-align: top;
    display: none;
  }
}

.application--hint {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.help.is-before-input {
  margin-top: -0.6rem;
  font-size: 0.8rem;
}

.content p.help.is-before-input {
  margin-bottom: 0.5rem;
}

span.tun-film-date {
  min-width: 180px;
  display: inline-block;
  margin-bottom: 2px;
}

span.tun-film-label {
  font-weight: bold;
}

.socialaccount_providers {
  display: flex;
  justify-content: space-evenly;
  li {

  }
}

.login-password {
  margin-top: 2rem;
  margin-left: 0.5rem;
  padding: 1rem;
  background: $grey-lighter;
}
